import '../../../../../../src/features/stock/commande/Commande.scss'
import { Button, Form, Input, Modal, notification, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { ColumnsType } from 'antd/es/table'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { t } from 'i18next'
import _, { stubTrue } from 'lodash'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootState } from '../../../../../app/store'
import CLOSE_ICON from '../../../../../assets/images/close-success.png'
import ERROR from '../../../../../assets/images/error.png'
import SUCCESS from '../../../../../assets/images/success.png'
import CSwitch from '../../../../../components/Common/CSwitch/CSwitch'
import Loading from '../../../../../components/Common/Loading/Loading'
import InputRadio from '../../../../../components/Common/ModalDetails/InputRadio'
import Define from '../../../../../constants/define'
import { ActionType, StatusAPI, TableType } from '../../../../../enum'
import { CommandeScene } from '../../../../../enum/CommandeScene'
import { StatusCommande } from '../../../../../enum/StatusCommande'
import { focusById } from '../../../../../hook/usePressTab'
import commandeApi from '../../../../../http/commandeApi'
import { Adresses, AttCommon, Contact } from '../../../../../models'
import {
  getAllCommande,
  initialCommande,
} from '../../../../../redux/reducers/commandeSlice'
import {
  clearCurrentDestinataire,
  getDestinataireByCode,
} from '../../../../../redux/reducers/destinataireSlice'
import {
  clearCurrentTransporteur,
  getTransporteurByCode,
} from '../../../../../redux/reducers/transporteurSlice'
import {
  getCurrentFiltersText,
  removeTones,
  renderNull,
} from '../../../../../utils'
import { convertDataTabletoAPI } from '../../../../../utils/commande/convertDataTabletoAPI'
import FormDestination from '../../../components/commande/FormDestination'
import FormLivraison from '../../../components/commande/FormLivraison'
import { DataTableParam } from '../../../stockscreen/component/model'
import SectionLayout from '../../../stockscreen/component/SectionLayout'
import { NameAndValue } from '../../../stockscreen/component/Wrapper'
import { ModalName, ModalType } from '../../customModalSlice'
import './Commande.scss'
import DetailSection from './DetailSection'

interface ActionProps {
  onClickPlusBtn?(): void

  isShowIconPlus?: boolean
}

const Action = ({
  isShowIconPlus = true,
  onClickPlusBtn = () => {},
}: ActionProps) => {
  const onClickPlus = (): void => {
    if (onClickPlusBtn) onClickPlusBtn()
  }

  return (
    <div className="flex items-center gap-3">
      {isShowIconPlus && (
        <i className={'icon-add-plus'} onClick={onClickPlus}></i>
      )}
    </div>
  )
}

const CommandeCreate = () => {
  const columnsAdresses: ColumnsType<Adresses> = [
    {
      title: 'Adresse',
      dataIndex: 'addresse',
      key: 'addresse',
      width: 370,
      render: (_, row) =>
        `${renderNull(row.addresse as string)} - ${renderNull(
          row.code_postal as string
        )} - ${renderNull(row.ville as string)} - ${renderNull(
          row.pays as string
        )} (${renderNull(row.iso as string)})`,
    },
    {
      title: 'Complément',
      dataIndex: 'complement',
      key: 'complement',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: 'GLN',
      dataIndex: 'gln',
      key: 'gln',
      width: 110,
      render: (el) => renderNull(el),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 110,
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.ADDRESSE}
          handleCheckedRadio={() =>
            handleAttDestinataire(TableType.ADDRESSE, el)
          }
        />
      ),
    },
  ]

  const columnsContact: ColumnsType<Contact> = [
    {
      title: 'Contact',
      dataIndex: 'nom',
      key: 'contact',
      width: 170,
      render: (el) => renderNull(el),
    },
    {
      title: 'Tel',
      dataIndex: 'tel',
      key: 'tel',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: 'Mobile',
      dataIndex: 'mobile',
      key: 'mobile',
      width: 125,
      render: (el) => renderNull(el),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 225,
      render: (el) => renderNull(el),
    },
    {
      title: 'Actions',
      key: 'action',
      width: 110,
      render: (el) => (
        <InputRadio
          el={el}
          type={TableType.CONTACT}
          handleCheckedRadio={() =>
            handleAttDestinataire(TableType.CONTACT, el)
          }
        />
      ),
    },
  ]
  const commandeKey = useSelector((state: RootState) => state.selector).data
    .commande
  const { currentDestinataire } = useSelector(
    (state: RootState) => state.destinataire
  )
  const [searchParams] = useSearchParams()
  const pageIndex = Number(searchParams.get('page-index')) || 1
  const pageSize = Number(searchParams.get('page-size')) || 25
  const mustSearch = Boolean(searchParams.get('must-search')) || false
  const navigate = useNavigate()

  const [form] = Form.useForm()
  const initialValues = {
    commande: dayjs(dayjs(), t('date-format')),
    priorite: 5,
  }

  const dispatch = useDispatch()
  const { currentTransporteurList, currentTransporteur } = useSelector(
    (state: RootState) => state.transporteur
  )
  const { type } = useSelector((state: RootState) => state.modal)

  const { currentCommande } = useSelector((state: RootState) => state.commande)
  const [isCreateTransporteur, setIsCreateTransporteur] =
    useState<boolean>(false)
  const [isCreateDestinataire, setIsCreateDestinataire] =
    useState<boolean>(false)
  const [dataDestinataireAdresses, setDataDestinataireAdresses] = useState<
    Adresses[]
  >([])
  const [dataDestinataireContact, setDataDestinataireContact] = useState<
    Contact[]
  >([])
  const [gln, setGln] = useState<string>('')
  const client = localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
  const [errorNom, setErrorNom] = useState<string>('')
  const [errorClientCode, setErrorClientCode] = useState<string>('')
  const [attDestinataire, setAttDestinataire] = useState<AttCommon>()
  const [attTransporteur, setAttTransporteur] = useState<AttCommon>()

  const [detailData, setDetailData] = useState<DataTableParam[][]>([])
  const [photos, setPhotos] = useState<number>(0)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [isValidDetail, setIsValidDetail] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [displayRelay, setDisplayRelay] = useState<boolean>(false)
  const [activeLot, setActiveLot] = useState<boolean>(stubTrue)
  const goToEmptyTransporteur = useRef<HTMLInputElement>(null)
  const goToEmptyDestinataire = useRef<HTMLInputElement>(null)
  const [forceDlou, setForceDluo] = useState(false)
  //set relay detail null
  useEffect(() => {
    if (
      !currentTransporteurList.loading ||
      currentTransporteur.status === StatusAPI.calling
    ) {
      form.setFieldValue('relay_detail', '')
    }
  }, [form, currentTransporteurList.loading, currentTransporteur.status])

  useEffect(() => {
    if (isCreateTransporteur) {
      form.setFieldValue('relay_detail', '')
    }
  }, [form, isCreateTransporteur])

  useEffect(() => {
    if (currentTransporteur.transporteur?.relay) {
      setDisplayRelay(true)
      form.setFieldValue('relay_detail', '')
    } else {
      setDisplayRelay(false)
    }
  }, [currentTransporteur.transporteur, form])

  //set attDestinataire if find a destinataire after search
  useEffect(() => {
    if (currentDestinataire.destinataire) {
      setDataDestinataireAdresses(
        currentDestinataire.destinataire?.addresse as Adresses[]
      )
      setDataDestinataireContact(
        currentDestinataire.destinataire?.contact as Contact[]
      )

      setAttDestinataire({
        destinataire_id: currentDestinataire.destinataire?.id,
        address_id: currentDestinataire.destinataire?.addresse[0].id,
        contact_id: currentDestinataire.destinataire?.contact[0].id,
      })
    } else {
      setAttDestinataire({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDestinataire.action, currentDestinataire.destinataire])

  //set transporteur field value after search
  useEffect(() => {
    if (!currentTransporteur.transporteur) return

    form.setFieldValue(
      'transporteur',
      `${currentTransporteur.transporteur?.code}_${currentTransporteur.transporteur?.nom}`
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransporteur, isCreateTransporteur])

  //set destinataire field value after search
  useEffect(() => {
    if (!currentDestinataire.destinataire) return
    form.setFieldValue(
      'destinataire',
      `${currentDestinataire.destinataire?.code}_${currentDestinataire.destinataire?.nom}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDestinataire, isCreateDestinataire])

  //select the lastest created gln
  useEffect(() => {
    let selectedGLN = _.findLast(
      currentTransporteur.transporteur?.addresse,
      (item) => item !== undefined
    )
    setGln(selectedGLN?.gln as string)
  }, [currentTransporteur.transporteur?.addresse])

  //set attTransporteur if find a transporteur after search
  useEffect(() => {
    if (currentTransporteur.transporteur) {
      let currentTrans = currentTransporteur.transporteur
      setAttTransporteur({
        transporteur_id: currentTrans.id,
        address_id: currentTrans.addresse[currentTrans.addresse.length - 1].id,
        contact_id: currentTrans.contact[currentTrans.contact.length - 1].id,
      })
    } else {
      setAttTransporteur({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransporteur.action, currentTransporteur.transporteur])

  //noti success if create transporteur success
  useEffect(() => {
    if (
      currentTransporteur.status === StatusAPI.success &&
      currentTransporteur.action === ActionType.CREATE
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Création enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsCreateTransporteur(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTransporteur.status])

  //noti success if create destinataire success
  useEffect(() => {
    if (
      currentDestinataire.status === StatusAPI.success &&
      currentDestinataire.action === ActionType.CREATE
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Création enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      setIsCreateDestinataire(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDestinataire.status])

  useEffect(() => {
    if (
      currentCommande.status === StatusAPI.success &&
      type === ModalType.CREATION
    ) {
      notification.open({
        className: 'noti noti-success',
        message: (
          <div className="flex items-center">
            <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
            Succès: Commande enregistrée
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      handleClose()
      dispatch(getAllCommande({ isRealTime: false }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCommande.status])

  //handle attDestinataire when onclick radio button
  const handleAttDestinataire = (type: string, value: any) => {
    let myAttDestinataire = attDestinataire || {}
    switch (type) {
      case TableType.ADDRESSE:
        myAttDestinataire.address_id = value.id
        myAttDestinataire.destinataire_id = value.destinataire_id

        break
      case TableType.CONTACT:
        myAttDestinataire.contact_id = value.id
        myAttDestinataire.destinataire_id = value.destinataire_id

        break
      default:
        break
    }
    setAttDestinataire(myAttDestinataire)
  }

  //search transporteur by code
  const onChangeTransporteur = (newValue: NameAndValue, searchCode: string) => {
    const code = newValue.name
    if (code) {
      if (code === 'Enlèvement client') {
        setGln('')
        setDisplayRelay(false)
        return
      }
      dispatch(getTransporteurByCode({ code: searchCode }))
    }
  }

  //search destinataire by code
  const onChangeDestinataire = (newValue: NameAndValue, code: string) => {
    if (newValue.name) {
      dispatch(getDestinataireByCode({ code }))
    }
  }

  const handleClose = () => {
    form.resetFields()

    dispatch(initialCommande())
    dispatch(clearCurrentTransporteur())
    dispatch(clearCurrentDestinataire())

    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate(
        `/gestion-de-stock/commande?page-index=${pageIndex}&page-size=${pageSize}${getCurrentFiltersText()}`
      )
    }
  }

  const onFinishForm = (value: any) => {
    setSubmitted(true)
    if (!isValidDetail) return
    if (photos > 20) return
    setLoading(true)
    delete value.destinataire
    delete value.transporteur
    let detailDataMapping = convertDataTabletoAPI(detailData, false, true)
    for (let i = 0; i < detailDataMapping.length; i++) {
      detailDataMapping[i].created_by =
        localStorage.getItem(Define.USERNAME) || ''
      detailDataMapping[i].creater_id =
        localStorage.getItem(Define.USER_ID) || ''
      detailDataMapping[i].status_code = StatusCommande.CreatedCommande
      detailDataMapping[i].active_lot = activeLot
      if (detailDataMapping[i].is_manual_sscc)
        detailDataMapping[i].is_manual_dluo = false
      if (detailDataMapping[i].is_manual_sscc)
        detailDataMapping[i].is_manual_lot = false
    }
    commandeApi
      .createCommande({
        ...value,
        client_code_nom:
          localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || '',
        warehouse_code_nom:
          localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || '',
        company_code_nom:
          localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || '',
        chargement_at: value.chargement_at
          ? value.chargement_at.unix()
          : undefined,
        livraison_at: value.livraison_at
          ? value.livraison_at.unix()
          : undefined,
        destinataire_id: attDestinataire?.destinataire_id,
        des_addresse_id: attDestinataire?.address_id,
        des_contact_id: attDestinataire?.contact_id,
        transporteur_id: attTransporteur?.transporteur_id || '',
        tran_addresse_id: attTransporteur?.address_id || '',
        tran_contact_id: attTransporteur?.contact_id || '',
        imperatif: !value.imperatif ? false : true,
        status_code: StatusCommande.CreatedCommande,
        created_by: localStorage.getItem(Define.USERNAME),
        creater_id: localStorage.getItem(Define.USER_ID),
        modification_by: localStorage.getItem(Define.USERNAME) || '',
        modificationer_id: localStorage.getItem(Define.USER_ID) || '',
        com_article: detailDataMapping,
        photos: photos,
        scene: CommandeScene.EditScene,
        active_lot: activeLot,
      })
      .then((res) => {
        setLoading(false)
        let result = res.data.entry
        dispatch(clearCurrentDestinataire())
        dispatch(clearCurrentTransporteur())
        localStorage.setItem('mustSearchCmd', JSON.stringify(true))
        // reload current list page
        navigate(
          `/gestion-de-stock/commande/commande-edit/${
            result.id
          }?page-index=${pageIndex}&page-size=${pageSize}&must-search=${true}${getCurrentFiltersText()}`,
          {
            replace: true,
          }
        )
      })
      .catch((e) => {
        setLoading(false)
        let error = e.response.data.error
        if (error.includes('nom douplicate')) {
          setErrorNom('Numéro déjà existant')
          setErrorClientCode('')
        } else if (error.includes('N Client duplicate')) {
          setErrorClientCode('Numéro déjà existant')
          setErrorNom('')
          dispatch(initialCommande())
        } else if (error) {
          notification.open({
            className: 'noti noti-error',
            message: (
              <div className="flex items-center">
                <img src={ERROR} alt="error" width={50} className="mr-2.5" />
                Une erreur s'est produite. Merci de réessayer ultérieurement
              </div>
            ),
            placement: 'topRight',
            closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
            duration: 3,
          })
        }
      })
  }

  const onChangeDataDetail = (value: DataTableParam[][]) => {
    setDetailData(value)
  }

  const onChangePhotos = (value: number) => {
    setPhotos(value)
  }
  const onCheckValid = (isValid: boolean) => setIsValidDetail(isValid)

  const handleActiveCommande = (isActive: boolean) => {
    setActiveLot(isActive)
  }

  useEffect(() => {
    focusById(commandeKey['n-input'])
  }, [])

  const onChangeForcerDluo = (value: boolean) => {
    setForceDluo(value)
  }
  return (
    <>
      <Form
        id={ModalName.COMMANDE}
        onFinish={onFinishForm}
        form={form}
        initialValues={initialValues}
      >
        <Modal
          prefixCls="modal-new-stock"
          centered
          open={true}
          onCancel={handleClose}
          width={'90%'}
          className="border-solid border-secondary rounded-xl"
          footer={null}
          destroyOnClose={true}
        >
          {loading && <Loading />}
          <div
            className="flex items-baseline border-none pb-2 mb-2"
            style={{ borderBottom: '1px solid #B7B7B7' }}
          >
            <div className="flex items-center">
              <span className="mr-4 modal-title-heading-1">Création</span>
              <span className="text-[#20458F] text-2xl mr-3">Commande</span>

              <span>
                <CSwitch
                  id={commandeKey['active']}
                  nextId={commandeKey['n-input']}
                  onChange={(isActive) => {
                    handleActiveCommande(isActive)
                  }}
                  checked={activeLot}
                  defaultChecked={true}
                />
              </span>
              <span className="font-normal text-lg text-[#20458F]">Active</span>
            </div>
          </div>
          <div className="w-full overflow-y-auto main-content">
            <SectionLayout title="Informations">
              <div className="flex infomation-section justify-between ">
                <Form.Item
                  label="N°"
                  name="nom"
                  rules={[{ required: true, message: '' }]}
                  colon={false}
                  validateStatus={errorNom ? 'error' : undefined}
                  help={errorNom}
                >
                  <Input
                    autoFocus
                    id={commandeKey['n-input']}
                    data-next-id={commandeKey['n-client']}
                    data-previous-id={commandeKey['active']}
                    maxLength={20}
                    onChange={(e) => {
                      form.setFieldValue('nom', removeTones(e.target.value))

                      setErrorNom('')
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="N° client"
                  name="nom_client"
                  colon={false}
                  className="item-client-code"
                  validateStatus={errorClientCode ? 'error' : undefined}
                  help={errorClientCode}
                >
                  <Input
                    id={commandeKey['n-client']}
                    data-previous-id={commandeKey['n-input']}
                    data-next-id={commandeKey['priorite']}
                    maxLength={20}
                    onChange={(e) => {
                      form.setFieldValue(
                        'nom_client',
                        removeTones(e.target.value)
                      )

                      setErrorClientCode('')
                    }}
                  />
                </Form.Item>

                <Form.Item label="Client" colon={false}>
                  <Input
                    disabled
                    style={{ background: '#F2F2F2' }}
                    value={client}
                    placeholder={client}
                  />
                </Form.Item>
                <Form.Item
                  label="Priorité"
                  name="priorite"
                  colon={false}
                  className="priorite-item"
                >
                  <Select
                    id={commandeKey['priorite']}
                    aria-roledescription={commandeKey['Forcer-la-DLUO']}
                    aria-keyshortcuts={commandeKey['n-client']}
                    style={{ width: 76 }}
                  >
                    {[5, 4, 3, 2, 1].map((i) => (
                      <Select.Option key={i} value={i}>
                        {i}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </SectionLayout>

            <DetailSection
              forcerDluo={forceDlou}
              onChangeForcerDluo={onChangeForcerDluo}
              onChangePhotos={onChangePhotos}
              onChangeData={onChangeDataDetail}
              alert={
                submitted && !isValidDetail ? (
                  alertNode('Veuillez valider toutes ces lignes suivantes')
                ) : (
                  <></>
                )
              }
              onCheckValid={onCheckValid}
            />

            <FormLivraison
              isCreateTransporteur={isCreateTransporteur}
              setIsCreateTransporteur={setIsCreateTransporteur}
              goToEmptyTransporteur={goToEmptyTransporteur}
              isEmptyTransporteur={false}
              onChangeTransporteur={onChangeTransporteur}
              gln={gln}
              form={form}
              detailLength={detailData.length}
            />

            <FormDestination
              isCreateDestinataire={isCreateDestinataire}
              setIsCreateDestinataire={setIsCreateDestinataire}
              isEmptyDestinataire={false}
              onChangeDestinataire={onChangeDestinataire}
              currentTransporteur={currentTransporteur}
              form={form}
              goToEmptyDestinataire={goToEmptyDestinataire}
              currentDestinataire={currentDestinataire}
              dataDestinataireAdresses={dataDestinataireAdresses}
              dataDestinataireContact={dataDestinataireContact}
              handleAttDestinataire={handleAttDestinataire}
              displayRelay={displayRelay}
            />

            <SectionLayout title="Commentaire">
              <div className="flex">
                <Form.Item
                  name={['com_preparation', 'comment']}
                  label="Préparation"
                  colon={false}
                >
                  <TextArea
                    style={{
                      height: 100,
                      width: 350,
                      resize: 'none',
                      marginLeft: '7%',
                    }}
                    showCount
                    maxLength={200}
                    id={commandeKey['preparation-commentaire']}
                    data-previous-id={commandeKey['code-nom-destinataire']}
                    data-next-id={commandeKey['livraison-commentaire']}
                  />
                </Form.Item>
                <Form.Item
                  name={['com_livraison', 'comment']}
                  label="Livraison"
                  colon={false}
                  className="ml-10"
                >
                  <TextArea
                    style={{
                      height: 100,
                      width: 350,
                      resize: 'none',
                      marginLeft: '7%',
                    }}
                    showCount
                    maxLength={200}
                    id={commandeKey['livraison-commentaire']}
                    data-previous-id={commandeKey['preparation-commentaire']}
                    data-next-id={commandeKey['Sauvegarder']}
                  />
                </Form.Item>
              </div>
            </SectionLayout>
          </div>
          <div className="footer-content flex justify-end items-center">
            <Button
              id={commandeKey['Sauvegarder']}
              data-previous-id={commandeKey['livraison']}
              data-next-id={commandeKey['n-input']}
              disabled={
                loading ||
                currentDestinataire.status === StatusAPI.calling ||
                currentTransporteur.status === StatusAPI.calling
              }
              prefixCls=" text-btn-submit-last-modal"
              className={clsx(
                'btn-submit-modal text-btn-submit-last-modal hover:text-white',
                !loading ? 'cursor-pointer' : 'cursor-not-allowed'
              )}
              key="submit"
              htmlType="submit"
              form={ModalName.COMMANDE}
            >
              Sauvegarder
            </Button>
          </div>
        </Modal>
      </Form>
    </>
  )
}

export default CommandeCreate

const alertNode = (text: string): ReactNode => (
  <span
    style={{ fontSize: 12, color: 'red', marginLeft: 5 }}
  >{`(${text})`}</span>
)
